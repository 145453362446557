body {
  background-color: #000000;  /* Black */
  font-family: 'Courier New', monospace;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.console {
  background-color: #000000;  /* Black */
  border-radius: 10px;
  padding: 20px;
  width: calc(93ch + 40px);
  height: calc(1.2em * 24 + 40px);
  max-width: none;
  border: 1px solid #333;  /* Optional: Adding a subtle border for definition */
}

.console-header {
  background-color: #333333;  /* Slightly lighter gray */
  border-radius: 10px 10px 0 0;
  padding: 5px 15px;
  color: #888888;  /* Medium gray */
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
}

.console-title {
  display: flex;
  align-items: center;
}

.console-title .red, .console-title .yellow, .console-title .green {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.console-title .red { background-color: #FF5F57; }
.console-title .yellow { background-color: #FFBD2E; }
.console-title .green { background-color: #27C93F; }

.console-body {
  padding: 10px;
  color: #a0a0a0;  /* Changed to a lighter gray */
  font-size: 1.2em;
  font-family: 'Courier New', monospace;
}

/* ... rest of your styles ... */

/* ... existing styles ... */

.console-actions {
  display: flex;
  justify-content: space-between;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button.execute {
  background-color: #27C93F;
  color: #1D1D1D;
}

.button.execute:hover {
  background-color: #1faa34;
}

.button.terminate {
  background-color: #FF5F57;
  color: #1D1D1D;
}

.button.terminate:hover {
  background-color: #d9534f;
}



.console-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;  /* Adds space between the console and the button */
}


.highlight {
  color: #FF5F57;  /* You can change this color to whatever you prefer */
}

.command-link {
  color: #a0a0a0;  /* Or any color you prefer */
  text-decoration: none;
}

.command-link:hover {
  text-decoration: underline;
}
.development-label {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #FF5F57;  /* You can change this color to whatever you prefer */
  font-size: 0.8rem;
}